import _axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { plainToClass } from 'class-transformer';
import Swal from 'sweetalert2';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  ignore401?: boolean;
  disableErrorAlert?: boolean;
}

interface CustomAxiosInstance extends AxiosInstance {
  getUri(config?: CustomAxiosRequestConfig): string;
  request<T = any, R = AxiosResponse<T>>(config: CustomAxiosRequestConfig): Promise<R>;
  get<T = any, R = AxiosResponse<T>>(url: string, config?: CustomAxiosRequestConfig): Promise<R>;
  delete<T = any, R = AxiosResponse<T>>(url: string, config?: CustomAxiosRequestConfig): Promise<R>;
  head<T = any, R = AxiosResponse<T>>(url: string, config?: CustomAxiosRequestConfig): Promise<R>;
  options<T = any, R = AxiosResponse<T>>(url: string, config?: CustomAxiosRequestConfig): Promise<R>;
  post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<R>;
  put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<R>;
  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<R>;
}

interface CustomAxiosError extends AxiosError {
  config: CustomAxiosRequestConfig;
}

const axios: CustomAxiosInstance = _axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_SERVER_URL,
  withCredentials: true,
});

axios.interceptors.response.use(
  r => r,
  (e: CustomAxiosError) => {
    if (!e.response) {
      if (!e.config?.disableErrorAlert){
        if (typeof window !== 'undefined') {
          Swal.fire({title: '오류', text: e.toString(), heightAuto: false});
        } else {
          throw e;
        }
      }

      return Promise.reject(e);
    }

    const {
      config: {ignore401, disableErrorAlert},
      response: {
        data: {code, errors, message},
        status,
      },
    } = e;

    if (status === 401 && !ignore401) {
      if (typeof window !== 'undefined') {
        Swal.fire({title: '세션만료', heightAuto: false});

        console.log('세션만료')
      
        window.location.reload();
      }

      throw new Error('세션만료');
    } else {
      if (!disableErrorAlert) {
        if (typeof window !== 'undefined') {
          Swal.fire({title: '오류', text: errors[0]?.reason || message || e.toString(), heightAuto: false});

        } else {
          throw e;
        }
      }
    }
    
    return Promise.reject(e);
  },
);

const request = async <T extends {}> (config: CustomAxiosRequestConfig, classType: any): Promise<T> => {
  config.url = encodeURI(config.url!);

  const r = await axios.request<T>(config);
  
  return plainToClass<T, AxiosResponse['data']>(classType, r.data);
}

export default request;
