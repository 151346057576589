import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ChannelService from "ChannelService";
import { plainToClass } from "class-transformer";
import Const from "Const";
import throttle from "lodash.throttle";
import { GetServerSideProps, NextPage } from "next";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import request from "request";
import LoadingStore from "stores/LoadingStore";
import Page from "types/Page";

interface Props {
  data: {
    pages: any[];
  }
}

const Index: NextPage<Props> = ({data}) => {
  const [pages, setPages] = useState<Page[] | null>(null);
  const router = useRouter();
  const [hasMore, setHasMore] = useState(true);

  const loadMorePages = useCallback(() => {
    if (LoadingStore.isLoading || !pages) return;

    LoadingStore.setIsLoading(true);

    request<Page[]>({url: `page?updatedAt=${pages[pages.length - 1]?.updatedAt || Const.MAX_TIMESTAMP}&listSize=6`, method: 'GET'}, Page)
      .then(r => {
        if (r.length < 6) {
          setHasMore(false);
        }

        setPages([...pages, ...r]);
      })
      .catch(() => {})
      .then(() => {
        LoadingStore.setIsLoading(false);
      });

  }, [pages, LoadingStore.isLoading]);

  const infiniteCallback = useMemo(() => throttle((e: any) => {
    if (e.target.scrollWidth - e.target.scrollLeft - e.target.offsetWidth < 100 && hasMore) {
      loadMorePages();
    }
  }, 200), [hasMore, loadMorePages]);

  const infiniteRef = useCallback((node) => {
    if (!node) return;

    node.onscroll = (e: any) => infiniteCallback(e);
  }, [infiniteCallback]);

  useEffect(() => {
    setPages(plainToClass(Page, data.pages));

    if (ChannelService) {
      ChannelService.boot({
        pluginKey: '530c8199-cd60-4c1f-ad95-5b0e4cc9770e'
      });
    }
  }, []);

  const movePage = useCallback((page) => {
    router.push(`/page/${page.name}`);
  }, [router]);

  return (
    <div className="w-full h-full bg-white text-center">
      <Image src={Const.LOGO_VECTOR} layout="fixed" width="180px" height="50px" />

      <Divider />

      <Typography variant="h6" className="font-bold mt-2 text-[#434343] tracking-tighter leading-6">탄소중립을 실천하는<br />스마트한 ECO 선거홍보실</Typography>
      <Typography variant="body1" className="font-bold mt-2 text-[#a3a3a3] tracking-tighter	">Done is better than Perfect.</Typography>


      {!!pages && (
        <div className="overflow-x-scroll overflow-y-hidden whitespace-nowrap touch-pan-x allchild:touch-pan-x text-left mt-10 px-8" ref={infiniteRef}>
          {pages.map((page, idx) => (
            <div className={`inline-flex flex-col bg-[#f0f0f0] w-[130px] max-w-[130px] h-[210px] max-h-[210px] mx-4`} key={idx} onClick={() => movePage(page)}>
              <Image src={page.titleImageURL} layout="fixed" width="130px" height="130px" />
              
              <div className="p-2 flex justify-between flex-col">
                <Typography variant="subtitle2">{page.personName}</Typography>
                <Typography variant="caption" className="text-[11px]" noWrap>{page.race}</Typography>
                <Typography variant="caption" className="text-[11px]" noWrap>{page.comment}</Typography>
              </div>
            </div>
          ))}
        </div>
      )}
      
      <div className="fixed left-[14px] bottom-[calc(18px+env(safe-area-inset-bottom))] leading-[0]">
        <Typography variant="h4" noWrap className="font-black text-mint2 text-center w-full block leading-none whitespace-pre-line mb-2">
          2022<span className="text-mint1">0601</span>
        </Typography>
      </div>
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async ctx => {
  const pages = await request({url: `page?updatedAt=${Const.MAX_TIMESTAMP}&listSize=6`, method: 'GET'}, null)
    .then(r => r)
    .catch(e => {
      throw e;
    });
  
  return {props: {data: {pages}}};
};

export default Index;